<script setup lang="ts">
const { auth } = useSupabaseClient()
const signingOut = ref(false)
const handleSignOut = async () => {
  signingOut.value = true
  await auth.signOut()
  signingOut.value = false
}
</script>
<template lang="pug">
button(
  :disabled="signingOut"
  @click="handleSignOut"
  class="btn btn-sm btn-outline"
)
  span(v-if="!signingOut") Sign out
  span(v-else)
    Icon(
      mb-2
      name="svg-spinners:180-ring-with-bg"
      text-md
      v-if="signingOut"
    )
    | &nbsp; Signing out
</template>
